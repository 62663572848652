.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Amatic SC", sans-serif;
  font-weight: bold;
  font-size: 1.75rem; 
  color: #467f8c;
  text-shadow: 1px 1px 2px gray;
  padding: 20px 0;
  box-sizing: border-box;
}

#lotus {
  height: 10vh;
  width: auto; 
  max-width: 100px; 
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .footerContainer {
    font-size: 1.25rem; 
  }

  #lotus {
    height: 8vh;
    max-width: 80px;
  }
}

@media screen and (max-width: 375px) {
  .footerContainer {
    font-size: 0.875rem; 
    padding: 15px 0;
  }

  #lotus {
    height: 6vh;
    max-width: 60px;
  }
}
