@import url("https://fonts.googleapis.com/css?family=Amatic+SC");
@import url("https://fonts.googleapis.com/css?family=Just+Another+Hand|Open+Sans+Condensed:300");
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

.navbarContainer {
  position: sticky;
  top: 0;
  z-index: 7;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1rem;
  background: linear-gradient(
    270deg,
    white,
    #7d6e60,
    #b3aca3,
    #46392f,
    white,
    #467f8c,
    #659fa6,
    whitesmoke
  );
  background-size: 600% 600%;
  animation: BackGroundAnimation 30s ease infinite;
}

.link {
  text-decoration: none;
  font-size: 2rem;
  font-family: "Amatic SC", sans-serif;
  font-weight: bold;
  color: rgb(85, 81, 81);
  padding: 0.5rem 1rem;
}

.link:hover {
  background-color: #b2c8d7;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
}

@media screen and (max-width: 768px) {
  .navbarContainer {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .link {
    font-size: 1.5rem;
    padding: 0.3rem 0.8rem;
    margin: 0.2rem;
  }
}

@media screen and (max-width: 375px) {
  .navbarContainer {
    padding: 0.5rem;
  }
  
  .link {
    font-size: 1.2rem;
    padding: 0.2rem 0.5rem;
  }
}

@keyframes BackGroundAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

