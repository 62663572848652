.acceuilContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.citationAcceuil {
  font-family: "Amatic SC", sans-serif;
  font-weight: bold;
  font-size: 2.5rem;
  opacity: 0.6;
  text-align: center;
  color: #2a2929;
  margin: 5% 0;
  max-width: 80%;
}

#imgYogiste {
  width: 100%;
  max-width: 600px;
  height: auto;
  aspect-ratio: 16 / 9;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  object-fit: cover;
  box-shadow: 0px 11px 5px 0px rgba(143, 140, 143, 1);
  margin: auto;

  z-index: 1;
}

@media screen and (max-width: 768px) {
  .citationAcceuil {
    font-size: 2rem;
    max-width: 90%;
  }

  #imgYogiste {
    max-width: 80%;
  }
}

@media screen and (max-width: 375px) {
  .acceuilContainer {
    padding: 10% 0;
  }

  .citationAcceuil {
    font-size: 1.5rem;
    max-width: 95%;
  }

  #imgYogiste {
    max-width: 90%;
  }
}
