.coursTarifsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 2%;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  font-family: "Baloo 2", sans-serif;
  background-image: url("../../assets/pictures/goute_tarif.png");
  background-size: cover;
  background-position: center;
  box-shadow: 0px 11px 5px 0px rgba(143, 140, 143, 1);
  margin-bottom: 2%;
}

.horairesContainer, .tarifsContainer {
  flex: 1 1 45%;
  min-width: 300px;
  margin: 1%;
  padding: 20px;
  background-color: rgba(245, 245, 245, 0.6);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  color: black;
  border-radius: 20px;
  border: 3px solid grey;
  box-sizing: border-box;
}

.horaires {
  margin-top: 15%;
}

@media screen and (max-width: 768px) {
  .coursTarifsContainer {
    font-size: 18px;
  }
  
  .horairesContainer, .tarifsContainer {
    flex: 1 1 100%;
    margin: 2% 0;
  }
}

@media screen and (max-width: 375px) {
  .coursTarifsContainer {
    font-size: 16px;
    flex-direction: column;
  }
  
  .horairesContainer, .tarifsContainer {
    width: 100%;
    margin: 2% 0;
    padding: 15px;
    word-wrap: break-word;
    text-align: justify;
  }
  
  .horaires {
    margin-top: 10%;
  }
}
