.multipleDesignContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  margin: 5% auto;
  padding: 20px;
  font-size: 1.5rem;
  font-family: "Baloo 2", sans-serif;
  font-weight: bold;
  background-color: rgba(245, 245, 245, 0.6);
  border-radius: 20px;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  animation: fadein 1s;
}

#degasquet {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 10px 0;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@media screen and (max-width: 768px) {
  .multipleDesignContainer {
    width: 90%;
    font-size: 1.2rem;
    padding: 15px;
  }

  #degasquet {
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 375px) {
  .multipleDesignContainer {
    width: 95%;
    font-size: 1rem;
    padding: 10px;
  }

  #degasquet {
    width: 60px;
    height: 60px;
  }
}
