.parcoursContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2% 0;
  box-sizing: border-box;
}

.parcours {
  width: 80%;
  max-width: 1200px;
  margin: 3% 0;
  padding: 20px;
  font-size: 1.5rem;
  font-family: "Baloo 2", sans-serif;
  font-weight: bold;
  background-color: rgba(245, 245, 245, 0.6);
  border-radius: 20px;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  animation: fadein 1s;
}

.containerButton {
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-width: 1000px;
  margin: 3% 0;
}

.buttonMoreInfos {
  background-color: #467f8c;
  color: white;
  font-size: 1.8rem;
  font-family: "Amatic SC", sans-serif;
  font-weight: bold;
  padding: 15px 30px;
  border: none;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.buttonMoreInfos:focus {
  outline: none;
}

.buttonMoreInfos:hover {
  background-color: #3a6b76;
}

@media screen and (max-width: 768px) {
  .parcours {
    width: 90%;
    font-size: 1.2rem;
  }

  .containerButton {
    width: 90%;
    flex-wrap: wrap;
    justify-content: center;
  }

  .buttonMoreInfos {
    font-size: 1.5rem;
    padding: 12px 24px;
    margin: 10px;
  }
}

@media screen and (max-width: 375px) {
  .parcours {
    width: 95%;
    font-size: 1rem;
    padding: 15px;
  }

  .containerButton {
    width: 95%;
  }

  .buttonMoreInfos {
    font-size: 1.2rem;
    padding: 10px 20px;
    margin: 5px;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
