@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");

.placestuffContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2% 0;
  box-sizing: border-box;
}

.salleContainer, .stuffContainer {
  font-size: 1.5rem;
  font-family: "Baloo 2", sans-serif;
  font-weight: bold;
  width: 80%;
  max-width: 1200px;
  margin: 2% 0;
  padding: 20px;
  background-color: rgba(245, 245, 245, 0.6);
  border-radius: 20px;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
}

.stuffContainer {
  margin-bottom: 6%; 
}

.carrousel {
  text-align: center;
  background-color: whitesmoke;
  border-radius: 10px;
  height: 80vh;
  width: 60%;
  max-width: 1000px;
  margin: 10% 0; 
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.containerBack {
  min-height: 100vh;
  background-color: white;
}

@media screen and (max-width: 768px) {
  .salleContainer, .stuffContainer {
    font-size: 1.2rem;
    width: 90%;
    padding: 15px;
    margin: 3% 0;
  }

  .stuffContainer {
    margin-bottom: 5%; 
  }

  .carrousel {
    height: 50vh;
    width: 90%;
    margin: 5% 0; 
  }
}

@media screen and (max-width: 375px) {
  .salleContainer, .stuffContainer {
    font-size: 1rem;
    width: 95%;
    padding: 10px;
    margin: 4% 0;
  }

  .stuffContainer {
    margin-bottom: 8%; 
  }

  .carrousel {
    height: 40vh;
    width: 95%;
    margin: 8% 0; 
  }
}
